body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif !important;
}
.pac-container:after {
    height: 0;
    padding: 0;
}
.customDateSelector .MuiButtonBase-root.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected {
    color: #fff;
}

.new-sticky {
    position: sticky;
    position: -webkit-sticky !important;
    top: 60px;
    background: #f8f8f8;
    z-index: 9;
}

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

button.Mui-selected {
    color: #fff !important;
}
.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: #00773E;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
}

.icon-style:hover {
    animation: none;
    background: #fff;
    color: #00773E;
    border: 2px solid #00773E;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
